$(function () {
    function setupActiveNavigation() {
        var path = window.location.pathname;
        $('ul.nav.nav-sidebar li, ul.nav.navbar-nav li').each(function () {
            if (path.indexOf(this.id) > -1) {
                $(this).addClass('active');
            }
        });
    }

    function bindDatePickers() {
        $('.input-group.date').datepicker({
            autoclose: true,
            todayHighlight: true,
            format: 'yyyy-mm-dd',
            minDate: new Date(),
        });
        $('.input-group.date').bind('keydown keypress', function (e) {
            e.stopPropagation();
        });
    }

    setupActiveNavigation();
    bindDatePickers();
    $('[data-toggle="tooltip"]').tooltip();
});

(function () {
    var YueDiligenceContext = function () {
        return this;
    };

    $.extend(YueDiligenceContext.prototype, {
        createAlert: function (type, message) {
            var alertStr =
                '<div class="alert alert alert-' +
                type +
                ' alert-dismissible" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
                message +
                ' </div>';
            $('.alert-container').append(alertStr);
        },

        waitForFinalEvent: (function () {
            var timers = {};
            return function (callback, ms, uniqueId) {
                if (!uniqueId) {
                    uniqueId = "Don't call this twice without a uniqueId";
                }
                if (timers[uniqueId]) {
                    clearTimeout(timers[uniqueId]);
                }
                timers[uniqueId] = setTimeout(callback, ms);
            };
        })(),

        noRatingColor: '#666',
        neutralColor: '#f0ad4e',
        greaterColor: '#5cb85c',
        lesserColor: '#d9534f',
        peerColor: '#5d90a8',
        overallColor: '#4678EE',

        ratingColor: function (rating) {
            var result = this.noRatingColor;
            if (rating > 4) {
                result = this.greaterColor;
            } else if (rating > 3) {
                result = this.neutralColor;
            } else if (rating <= 3 && rating) {
                var result = this.lesserColor;
            }
            return result;
        },

        ratingLabel: function (rating) {
            var result = 'label-danger';
            if (rating > 4) {
                result = 'label-success';
            } else if (rating > 3) {
                result = 'label-warning';
            } else if (rating == null) {
                result = 'label-info';
            }
            return result;
        },

        ratingWell: function (rating) {
            var result = 'well-danger';
            if (rating > 4) {
                result = 'well-success';
            } else if (rating > 3) {
                result = 'well-warning';
            } else if (rating == -1) {
                result = 'well-locked';
            }
            return result;
        },

        progressStyle: function (percentComplete) {
            var result = 'progress-bar-danger';
            if (percentComplete > 75) {
                result = 'progress-bar-success';
            } else if (percentComplete > 35) {
                result = 'progress-bar-warning';
            }
            return result;
        },

        displayResponse: function (title, message) {
            bootbox.dialog({
                message: message,
                title: title,
                buttons: {
                    yes: {
                        label: 'OK',
                        className: 'btn-primary',
                        callback: function () {},
                    },
                },
            });
        },

        mustShowUpgradeModel: function () {
            var result = !isPaidCompany;
            if (result) {
                var lastUpgradePrompt = getCookie('lastUpgradePrompt' + reportPlan);
                if (lastUpgradePrompt != null) {
                    var then = moment(lastUpgradePrompt);
                    var now = moment();
                    var daysBetween = now.diff(then, 'days');
                    if (daysBetween < 7) {
                        result = false;
                    }
                }
            }
            return result;
        },

        showUpgradeModal: function () {
            if (this.mustShowUpgradeModel()) {
                $('#upgrade-modal').modal({
                    backdrop: 'static',
                    keyboard: false,
                });
            }
        },

        upgradePlan: function () {
            window.location.href = '/profile/upgrade-plan';
        },

        upgradePlanLater: function () {
            setCookie('lastUpgradePrompt' + reportPlan, moment().toISOString());
            $('#upgrade-modal').modal('hide');
        },
    });

    window.yueDiligence = new YueDiligenceContext();
})();

Highcharts.setOptions({
    credits: {
        enabled: false,
        text: '© ' + moment().format('YYYY') + ' Yue Diligence',
        href: '',
    },
    plotOptions: {
        series: {
            animation: false,
        },
    },
    legend: {
        enabled: false,
    },
});

const dataTableFunctions = {
    dataTablesBooleanColumn: dataTablesBooleanColumn,
};

window.dataTableFunctions = dataTableFunctions;

function dataTablesBooleanColumn(data, type, full) {
    var result = '<span class="label label-success">True</span>';
    if (data === false) {
        result = '<span class="label label-danger">False</span>';
    }
    return result;
}

function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
