(function() {

    angular
        .module('app')
        .factory('chartService', chartService);

    /*@ngInject*/
    function chartService($window) {

        var service = {
            getCategory: getCategory,
            getCategoryById: getCategoryById,
            getCategories: getCategories,
            getChartData: getChartData,
            getPeerGroups: getPeerGroups
        };

        return service;

        function getCategory(category) {
            return getCategoryById(category.id);
        }

        function getCategoryById(categoryId) {
            var result;
            var categories = getCategories();
            var filteredCategories = categories.filter(function(item) {
                return item.id == categoryId;
            });
            if (filteredCategories.length > 0) {
                result = filteredCategories[0];
            }
            return result;
        }

        function getPeerGroups() {
            function createPeerGroup(name, value, data) {
                return {name: name, value: value, data: data};
            }

            var peerGroupAll = createPeerGroup('ALL', 'All', $window.peerRatingsCategories);
            var peerGroupStage = createPeerGroup('STAGE', 'By Stage', $window.peerRatingsStage);
            var peerGroupIndustry = createPeerGroup('INDUSTRY', 'By Industry', $window.peerRatingsIndustry);
            var result = [];
            result.push(peerGroupAll, peerGroupStage, peerGroupIndustry);
            return result;
        }

        function getCategories() {
            var result = [];
            result.push($window.mainCategory);
            result = result.concat($window.mainCategory.items);
            return result;
        }

        function getChartData(category, peerGroup) {
            var result = {
                categoryData: [],
                myRatingData: [],
                myPreviousRatingData: [],
                peerRatingData: [],
                overallRatingData: []
            }

            var overallRatingToUse = category.rating;
            category.items.forEach(function(item) {
                result.categoryData.push(item.name);
                var rating = item.rating;
                var color = $window.yueDiligence.ratingColor(rating);
                if (rating == -1) {
                    rating = null;
                }
                var dataItem = {
                    color: color,
                    y: rating
                }

                var previousRating = item.previousRating;
                var color = $window.yueDiligence.ratingColor(previousRating);
                var previousDataItem = {
                    color: color,
                    y: previousRating < 0 ? 0 : previousRating
                }
                result.myRatingData.push(dataItem);
                result.myPreviousRatingData.push(previousDataItem);
                var peerRating = getPeerRating(peerGroup, item.id);
                result.peerRatingData.push(peerRating);
                result.overallRatingData.push(overallRatingToUse)
            });
            return result;
        }

        function getPeerRating(peerGroup, categoryId) {
            var result = 0;
            peerGroup.data.filter(function(item) {
               return item.categoryId == categoryId;
            }).forEach(function(item) {
                result = item.rating;
            })
            return result;
        }
    }

})();