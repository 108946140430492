const app = angular
    .module('app', ['ngMessages', 'ui.bootstrap'])
    .config(/*@ngInject*/ function ($httpProvider) {
        var token = $("meta[name='_csrf']").attr("content");
        var header = $("meta[name='_csrf_header']").attr("content");

        $httpProvider.defaults.headers.post = {
            'X-CSRF-TOKEN': token,
            'Content-Type': "application/json; charset=utf-8"
        };
    })
    .value('$anchorScroll', angular.noop);

export default app;
