import app from '@app/app';

class LicencesController {

    /*@ngInject*/
    constructor($uibModal, licencesService, plans, corporateExpiryDate) {
        this.$uibModal = $uibModal;
        this.licencesService = licencesService;
        this.plans = plans;
        this.corporateExpiryDate = corporateExpiryDate;
    }

    showGenerateLicences() {
        this.$uibModal.open({
            component: "generateLicences",
            resolve: {
                plans: () => this.plans,
                expiryDate: () => this.corporateExpiryDate
            },
            keyboard: false,
            backdrop: 'static'
        }).result.then(result => {
            this.licencesService.generateLicences(companyId, result)
                .then(() => {
                    this.refreshLicences();
                });
        }, function (reason) {
            console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
        });
    }

    refreshLicences() {
        const dataTable = $('#licences').DataTable();
        dataTable.ajax.reload();
    }

}

app.controller('LicencesController', LicencesController);
