(function() {

    angular
        .module('app')
        .directive('ydChartSelectorItem', chartSelectorItem);

    function chartSelectorItem() {
        var directive = {
            template: '<button type="button" class="btn graph-type-btn" ng-class="[vm.buttonClass, {selected: vm.selected}]" ng-click="vm.f(this)"></button>',
            restrict: 'E',
            link: link,
            bindToController: true,
            controller: controller,
            controllerAs: 'vm',
            require: '^ydChartSelector',
            scope: {
                onClick: '&',
                buttonClass: '@'
            }
        };
        return directive;

        function link(scope, element, attrs, chartSelectorController) {
            chartSelectorController.addItem(scope.vm);
        }

        function controller() {
            var vm = this;
            vm.selected = false;
            vm.f = f;

            function f($scope) {
                $scope.$parent.$parent.vm.selectItem(vm);
            }
        }
    }

})();