(function() {

    angular
        .module('app')
        .directive('ydChart', chart);

    chart.$inject = ['$window', 'chartService'];

    var template =
        '<div class="row" id="row-chart" ng-cloak="">' +
            '<div id="chart" class="col-xs-12 graph">' +
                '<div class="graph-loader"></div>' +
            '</div>' +
            '<div class="legend-panel legend-bottom text-center">' +
                '<div class="legend-icon legend-icon-image"></div>' +
                '<label>{{vm.companyName}}</label>' +
                '<div class="legend-icon legend-icon-color legend-overall"></div>' +
                '<label>Section Average</label>' +
                '<div class="legend-icon legend-icon-color legend-peers"></div>' +
                '<label>Peers</label>' +
            '</div>' +
        '</div>';

    function chart($window, chartService) {

        var directive = {
            template: template,
            restrict: 'E',
            bindToController: {
                categoryId: '=',
                companyName: '@',
                showCopyright:  '='
            },
            controller: controller,
            controllerAs: 'vm',
            scope: true
        };

        return directive;

        /*@ngInject*/
        function controller($scope, $element) {
            var vm = this;
            vm.items = [];
            vm.drawColumnChart = drawColumnChart;

            var chartData = null;

            $scope.$watch('vm.categoryId', function (categoryId) {
                loadChartData(categoryId);
                drawColumnChart($element)
            });

            function loadChartData(categoryId) {
                var category = chartService.getCategoryById(categoryId);
                var peerGroups = chartService.getPeerGroups();
                chartData = chartService.getChartData(category, peerGroups[0]);
            }

            function drawColumnChart(element) {
                YdCharts.drawColumnChart(element.find('div#chart'), chartData, vm.showCopyright);
            }

        }

    }
})();