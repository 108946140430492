const app = angular.module('app');

app.component('generateLicences', {
    // @ts-ignore
    template: require('./generate-licences.component.html'),
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: class {
        plans = [];
        model = {
            plan: null,
            expiryDate: null,
            count: 1
        };

        $onInit() {
            this.plans = this.resolve.plans;
            const date = this.resolve.expiryDate.format('YYYY-MM-DD');
            this.model.expiryDate = date;
            $('.input-group.expiry-date').datepicker({
                autoclose: true,
                todayHighlight: true,
                format: 'yyyy-mm-dd',
                startDate: new Date(),
                defaultViewDate: new Date(date)
            });

        }

        ok() {
            this.close({$value: this.model});
        }

        cancel() {
            this.dismiss({$value: 'cancel'});
        }

    }
});
