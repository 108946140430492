(function() {

    angular
        .module('app')
        .directive('ydCategoryGrid', categoryGrid);

    categoryGrid.$inject = ['$window'];

    var template =
        '<div class="row ng-cloak">' +
            '<div class="col-xs-12 col-sm-12 section-summary">' +
                '<div class="well well-sm" ng-class="ctrl.getHeaderClass(ctrl.category)">' +
                    '<span>{{ctrl.category.name}}</span>' +
                    '<div class="score">' +
                        '<div ng-if="ctrl.category.rating > -1" class="pull-right section-score"><span class="section-score-lg">{{ctrl.category.rating}}</span><span> / 5</span></div>' +
                        '<span ng-if="ctrl.category.rating == -1" class="pull-right section-score"><span class="glyphicon glyphicon-lock"></span></span>' +
                        '<div class="section-score score-change" ng-class="ctrl.getRatingChangeClass(ctrl.category)">' +
                            '<span ng-if="ctrl.getRatingChange(ctrl.category) != \'\'" class="glyphicon" ng-class="ctrl.getRatingIcon(ctrl.category)"></span>' +
                            '<span ng-if="ctrl.getRatingChange(ctrl.category) != 0 && ctrl.getRatingChange(ctrl.category) != \'\'">{{ctrl.getRatingChange(ctrl.category)}}</span>' +
                            '<span ng-if="ctrl.getRatingChange(ctrl.category) == 0 || ctrl.getRatingChange(ctrl.category) == null" class="glyphicon glyphicon-minus"></span>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<table class="table table-bordered section-summary-table">' +
                    '<tbody>' +
                        '<tr ng-repeat="item in ctrl.category.items">' +
                            '<td>{{item.name}}</td>' +
                            '<td ng-if="item.rating > -1" class="summary-item-score">{{item.rating | number:1}}</td>' +
                            '<td ng-if="item.rating == -1" class="summary-item-score"><span class="glyphicon glyphicon-lock"></span></td>' +
                            '<td class="summary-item-score" ng-class="ctrl.getRatingChangeClass(item)"><span class="glyphicon" ng-class="ctrl.getRatingIcon(item)"></span> {{ctrl.getRatingChange(item)}}</td>' +
                        '</tr>' +
                    '</tbody>' +
                '</table>' +
            '</div>' +
        '</div>';

    function categoryGrid($window) {

        var directive = {
            restrict: 'E',
            template: template,
            scope: true,
            bindToController: {
                category: '='
            },
            controllerAs: 'ctrl',
            controller: function () {
                this.getHeaderClass = getHeaderClass;
                this.getRatingChange = getRatingChange;
                this.getRatingChangeClass = getRatingChangeClass;
                this.getRatingIcon = getRatingIcon;

                function getHeaderClass(category) {
                    var rating = category.rating;
                    return $window.yueDiligence.ratingWell(rating);
                }

                function getRatingChange(categoryItem) {
                    var result = Math.abs(categoryItem.ratingChange);
                    if (result == 0) {
                        result = "";
                    } else {
                        result = result.toFixed(1);
                    }
                    return result;
                }

                function getRatingChangeClass(categoryItem) {
                    var result = 'rating-unchanged';
                    if (categoryItem.ratingChange < 0) {
                        result = 'rating-down';
                    } else if (categoryItem.ratingChange > 0) {
                        result = 'rating-up';
                    }
                    return result;
                }

                function getRatingIcon(categoryItem) {
                    var result = 'glyphicon-minus';
                    if (categoryItem.ratingChange < 0) {
                        result = 'glyphicon-circle-arrow-down';
                    } else if (categoryItem.ratingChange > 0) {
                        result = 'glyphicon-circle-arrow-up';
                    }
                    return result;
                }
            }

        };
        return directive;

    }
})();