(function () {

    angular
        .module('app')
        .factory('licencesService', licencesService);

    /*@ngInject*/
    function licencesService($http) {

        const service = {
            generateLicences: generateLicences,
        };

        return service;

        function generateLicences(companyId, model) {
            let config = {
                url: '/admin/corporates/' + companyId + '/licences/generate',
                method: 'POST',
                data: model
            };
            return $http(config);
        }


    }

})();