(function() {

    angular
        .module('app')
        .directive('ydChartSelector', chartSelector);

    function chartSelector() {
        var directive = {
            template: '<div class="form-group graph-type-select pull-right" ng-transclude=""></div>',
            link: link,
            controller: controller,
            controllerAs: 'vm',
            transclude: true,
            scope: {
                category: '='
            }
        };
        return directive;

        function controller() {
            var vm = this;
            vm.items = [];

            vm.addItem = addItem;
            vm.selectItem = selectItem;

            function addItem(item) {
                vm.items.push(item);

                if(vm.items.length === 1) {
                    item.selected = true;
                }
            }

            function selectItem(item) {
                resetUi(vm);
                item.selected = true;
                item.onClick();
            }

        }

        function link(scope, element, attrs, vm) {
            scope.$watch('vm.category', function(category) {
                refreshUi(vm);
            });

            function refreshUi(vm) {
                resetUi(vm);
                vm.items[0].selected = true;
            }

        }

        function resetUi(vm) {
            vm.items.forEach(function(value) {
                value.selected = false;
            });
        }

    }

})();