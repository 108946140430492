import app from '@app/app';
import urlcat from 'urlcat';

class CorporateDashboardCompaniesController {
    companies = [];
    companySortOptions = [];
    searchQuery = {
        companyName: null,
        sortBy: null,
    };

    /*@ngInject*/
    constructor($window, $http, latestCompanies, companySortOptions) {
        this.$window = $window;
        this.$http = $http;
        this.companies = latestCompanies;
        this.companySortOptions = companySortOptions;
    }

    gotoFullReport(reportId) {
        this.$window.location.href = `/reports/${reportId}/full`;
    }

    sortByChanged() {
        this.search();
    }

    search() {
        const url = urlcat('/corporate-dashboard-companies', {
            name: this.searchQuery.companyName,
            sortBy: this.searchQuery.sortBy,
        });
        this.$http.get(url).then(response => {
            this.companies = response.data;
        });
    }
}

app.controller('CorporateDashboardCompaniesController', CorporateDashboardCompaniesController);
