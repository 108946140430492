import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import addNoData from "highcharts/modules/no-data-to-display";
addMore(Highcharts);
addNoData(Highcharts);

const YdCharts = {
    drawSpiderChart: drawSpiderChart,
    drawColumnChart: drawColumnChart,
    drawLineChart: drawLineChart,
    drawPieChart: drawPieChart,
    drawSimpleBarChart: drawSimpleBarChart,
    drawValuationChart: drawValuationChart
};

window.YdCharts = YdCharts;

function drawSpiderChart(element, data, showCopyright) {

    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        chart: {
            polar: true,
            type: 'line'
        },

        title: {
            text: null,
        },

        pane: {
            size: '90%'
        },

        xAxis: {
            categories: data.categoryData,
            tickmarkPlacement: 'on',
            lineWidth: 0
        },

        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0,
            max: 5
        },

        legend: {
            enabled: false
        },

        series: [{
            name: 'My rating',
            data: data.myRatingData,
            pointPlacement: 'on',
            color: 'grey',
            connectNulls: true,
            marker: {
                radius: 5
            }
        }, {
            name: 'My previous rating',
            data: data.myPreviousRatingData,
            pointPlacement: 'on',
            color: '#d9534f',
            connectNulls: true,
            marker: {
                radius: 5
            }
        }, {
            name: 'Peer rating',
            data: data.peerRatingData,
            pointPlacement: 'on',
            color: window.yueDiligence.peerColor,
            dashStyle: 'shortdot',
            marker: {
                radius: 5
            }
        }]
    });
}

function drawColumnChart(element, data, showCopyright) {
    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        xAxis: {
            categories: data.categoryData
        },
        yAxis: [
            {
                title: {
                    text: null
                },
                min: 0,
                max: 5
            }, {
                title: {
                    text: null
                },
                min: 0,
                max: 5,
                opposite: true,
                linkedTo:0
            }, {
                title: {
                    text: null
                },
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            text: null
        },
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
            type: 'column',
            name: 'My rating',
            data: data.myRatingData,
            pointPadding: 0.0,
            pointPlacement: 0
        }, {
            type: 'column',
            name: 'Peer rating',
            color: window.yueDiligence.peerColor,
            data: data.peerRatingData,
            pointPadding: 0.3,
            pointPlacement: 0
        }, {
            type: 'spline',
            name: 'Overall rating',
            data: data.overallRatingData,
            color: window.yueDiligence.overallColor,
            lineWidth: 4
        }]
    });
}

function drawLineChart(element, data, showCopyright) {
    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: data.categoryData
        },
        yAxis: {
            title: {
                text: null
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#808080'
            }],
            min: 0,
            max: 5,
            isDirty: true
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Your rating',
            data: data.myRatingData,
            color: 'grey',
            connectNulls: true,
            marker: {
                radius: 7
            }
        }, {
            name: 'Peer rating',
            data: data.peerRatingData,
            color: window.yueDiligence.peerColor,
            dashStyle: 'shortdot',
            marker: {
                radius: 7
            }
        }, {
            name: 'Overall rating',
            data: data.overallRatingData,
            color: window.yueDiligence.overallColor,
            dashStyle: 'shortdot',
            marker: {
                radius: 7
            }
        }]
    });
}

function drawPieChart(element, data, title, seriesName, legendEnabled, showCopyright) {
    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: title
        },
        legend: {
            enabled: true,
            itemStyle: {
                fontFamily: '\'Lato\', Arial, sans-serif',
                fontSize: '16px',
                fontWeight: 'normal'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f} %',
                    style: {
                        fontFamily: '\'Lato\', Arial, sans-serif'
                    }
                },
                showInLegend: true
            }
        },
        series: [{
            name: seriesName,
            colorByPoint: true,
            data: data
        }]
    });
}

function drawSimpleBarChart(element, sequence1Data, sequence2Data, title, yTitle, labelPrefix, labelSuffix, showDataLabels, series1Title, showCopyright) {
    if (!labelPrefix) {
        labelPrefix = "";
    }
    if (!labelSuffix) {
        labelSuffix = "";
    }
    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        xAxis: {
            categories: sequence1Data.years,
            dataLabels: {
                style: { fontFamily: '\'Lato\', Arial, sans-serif'}
            }
        },
        yAxis: [
            {
                /*labels: {
                    format: labelPrefix + ' {value} ' + labelSuffix
                },*/
                title: {
                    text: null
                }//,
                //min: 0
                //max: 5
            }, {
                title: {
                    text: null
                }/*,
                min: 0*/
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            text: title
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                dataLabels: {
                    enabled: showDataLabels,
                    color: '#606060',
                    /*format: labelPrefix + ' {y} ' + labelSuffix,*/
                    style: { fontFamily: '\'Lato\', sans-serif'}
                }
            }
        },
        series: [{
            type: 'column',
            name: series1Title ? series1Title : null,
            data: sequence1Data.valueData,
        }, {
            type: 'spline',
            name: 'Overall rating',
            data: sequence2Data ? sequence2Data.valueData : null,
            color: window.yueDiligence.overallColor,
            lineWidth: 4,
        }]
    });
}

function drawValuationChart(element, data, yTitle, showCopyright) {
    element.highcharts({
        credits: {
            enabled: showCopyright,
            text: "\u00A9 " + moment().format("YYYY") + " Yue Diligence",
            href: ""
        },
        chart: {
            type: 'columnrange',
        },

        title: {
            text: null
        },

        xAxis: {
            categories: data.categories
        },

        yAxis: {
            title: {
                text: yTitle
            }
        },

        tooltip: {
            enabled: false
        },
        plotOptions: {
            columnrange: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            data: data.valueData,
        }]
    });
}
